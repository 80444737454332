import { Link, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Route } from 'next';
import NLink from 'next/link';

interface NextLinkProps<THref extends string>
   extends Omit<ChakraLinkProps, 'href'> {
   href: Route<THref>;
}

export function NextLink<T extends string>(props: NextLinkProps<T>) {
   const { href, ...rest } = props;

   return (
      <NLink href={href} legacyBehavior>
         <Link {...rest} />
      </NLink>
   );
}
