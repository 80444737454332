import { createInnerwellApiClient } from '@innerwell/api-client';
import { account } from '@innerwell/contracts';
import {
   JwtPayloadApiObject,
   MyPatientInsurance,
   MyPatientResponseDto,
} from '@innerwell/dtos';
import { generateSessionContext } from '@innerwell/sessionUtils';
import { getCookieSession } from '@innerwell/utils';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

// import { handleSentryException } from '@/utils/sentry';

type SessionUser = {
   sub: string;
   'cognito:username': string;
   // jwtToken: string; // removed
   exp: number;
   given_name: string;
   family_name: string;
   email: string;
   phone_number: string;
   'custom:welkinUserId': string;
   'custom:formsortUUID': string | undefined;
   insurance: MyPatientInsurance | null;
};

// TODO: maybe use webApiClient instead of apiClient, but without redirect
const apiClient = createInnerwellApiClient(
   {
      baseURL: getClientPublicRuntimeConfig().apiUrl,
      // using token interceptor here, we will try to refresh the token if it's expired
      // but not using onRefreshTokenError, this will be handled by generateSessionContext
      useRefreshTokenInterceptors: true,
      withCredentials: true,
      getCookieExp() {
         return getCookieSession<{ exp: number }>('')?.exp;
      },
   },
   {
      account,
   },
);

export const getSession = async (
   client = apiClient,
): Promise<SessionUser | null> => {
   const sessionInfo = getCookieSession<JwtPayloadApiObject>('');

   if (!sessionInfo) {
      return null;
   }

   let patient: MyPatientResponseDto | null = null;
   try {
      const patientRes = await client.account.getPatient();
      patient = patientRes.body;
   } catch (err) {
      // handleSentryException(err);
   }

   if (!patient) {
      return null;
   }

   return {
      ...sessionInfo,
      given_name: patient.firstName,
      family_name: patient.lastName,
      phone_number: patient.phone,
      insurance: patient.insurance,
   };
};

const { SessionProvider, useSession } = generateSessionContext<SessionUser>({
   redirectBaseUrl: '/auth/login',
   getSessionData: getSession,
});

export { SessionProvider, useSession };
