'use client';

import React, { useEffect, useState } from 'react';

type SessionGeneric<T> = T & { exp: number };

export type SessionContextValue<T> = (
   | { data: SessionGeneric<T>; status: 'authenticated' }
   | {
        data: null;
        status: 'unauthenticated' | 'loading';
     }
) & {
   clearSession: () => void;
   refetchSession: () => Promise<SessionGeneric<T> | null>;
};

type UseSessionOptions = {
   required?: boolean;
   onUnauthenticated?: () => void;
};

type GenerateSessionContextProps<T> = {
   redirectBaseUrl?: string;
   getSessionData: () => Promise<SessionGeneric<T> | null>;
};

export function generateSessionContext<T>({
   redirectBaseUrl,
   getSessionData,
}: GenerateSessionContextProps<T>) {
   const SessionContext = React.createContext<SessionContextValue<T>>({
      clearSession: () => {
         return;
      },
      refetchSession: async () => {
         return null;
      },
      data: null,
      status: 'loading',
   });

   function SessionProvider(props: {
      children: React.ReactNode;
      session?: SessionGeneric<T> | null;
   }) {
      const { children } = props;

      const [session, setSession] = useState<SessionGeneric<T> | null>(null);

      /** If session was passed, initialize as not loading */
      const [loading, setLoading] = React.useState(true);

      useEffect(() => {
         setLoading(true);

         const getSession = async () => {
            const newSession = await getSessionData();
            setLoading(false);

            if (newSession) {
               setSession(newSession);
            }
         };

         if (!session) {
            getSession();
         }
      }, []);

      const value = React.useMemo(
         () =>
            ({
               data: session,
               status: loading
                  ? 'loading'
                  : session
                    ? 'authenticated'
                    : 'unauthenticated',
               refetchSession: async () => {
                  const refetchedSession = await getSessionData();
                  setSession(refetchedSession);

                  return refetchedSession;
               },
               clearSession: () => {
                  setSession(null);
               },
            }) as SessionContextValue<T>,
         [session, loading],
      );

      return (
         <SessionContext.Provider value={value}>
            {children}
         </SessionContext.Provider>
      );
   }

   function useSession(options?: UseSessionOptions) {
      const value: SessionContextValue<T> = React.useContext<
         SessionContextValue<T>
      >(SessionContext as unknown as React.Context<SessionContextValue<T>>);
      if (!value && process.env.NODE_ENV !== 'production') {
         throw new Error(
            '`useSession` must be wrapped in a <SessionProvider />',
         );
      }

      const { required, onUnauthenticated } = options ?? {};

      const requiredAndNotLoading =
         required && value.status === 'unauthenticated';

      React.useEffect(() => {
         if (requiredAndNotLoading) {
            const url = `${redirectBaseUrl}?${new URLSearchParams({
               error: 'SessionRequired',
               callbackUrl: window.location.href,
            })}`;
            if (onUnauthenticated) onUnauthenticated();
            else window.location.href = url;
         }
      }, [requiredAndNotLoading]);

      if (requiredAndNotLoading) {
         return {
            data: null,
            status: 'loading',
            clearSession: value.clearSession,
            refetchSession: value.refetchSession,
         } as const;
      }

      return value;
   }

   return {
      SessionProvider,
      SessionContext,
      useSession,
   };
}
